import(/* webpackMode: "eager" */ "/app/node_modules/.pnpm/next@14.2.4_@babel+core@7.23.5_@playwright+test@1.46.1_babel-plugin-macros@3.1.0_react-dom@18_2dofjooqyr7k4n2zabpfosic3a/node_modules/next/dist/client/components/app-router.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/.pnpm/next@14.2.4_@babel+core@7.23.5_@playwright+test@1.46.1_babel-plugin-macros@3.1.0_react-dom@18_2dofjooqyr7k4n2zabpfosic3a/node_modules/next/dist/client/components/client-page.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/.pnpm/next@14.2.4_@babel+core@7.23.5_@playwright+test@1.46.1_babel-plugin-macros@3.1.0_react-dom@18_2dofjooqyr7k4n2zabpfosic3a/node_modules/next/dist/client/components/error-boundary.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/.pnpm/next@14.2.4_@babel+core@7.23.5_@playwright+test@1.46.1_babel-plugin-macros@3.1.0_react-dom@18_2dofjooqyr7k4n2zabpfosic3a/node_modules/next/dist/client/components/layout-router.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/.pnpm/next@14.2.4_@babel+core@7.23.5_@playwright+test@1.46.1_babel-plugin-macros@3.1.0_react-dom@18_2dofjooqyr7k4n2zabpfosic3a/node_modules/next/dist/client/components/not-found-boundary.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/.pnpm/next@14.2.4_@babel+core@7.23.5_@playwright+test@1.46.1_babel-plugin-macros@3.1.0_react-dom@18_2dofjooqyr7k4n2zabpfosic3a/node_modules/next/dist/client/components/render-from-template-context.js");
